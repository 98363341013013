import React from "react";
import styled from "styled-components";
import { IconProvider } from "utils/common.utils";
import { Empty, Input, Skeleton } from "antd";
import { BiSearch } from "react-icons/bi";
import {  PaginationContainer } from "Component";
import { enLangauge } from "Contents/en-langauge";
import { useAuth, useFetch } from "hooks";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN } from 'constants/localstorage.constants'; // Import the AUTH_TOKEN_KEY constant

// Ensure that clientWithHeaders is imported correctly from api/client.js
import { clientWithHeaders } from 'api/client';

const OfferListing = () => {
  const navigate = useNavigate();
  const {  session } = useAuth();

  const [state, SetState] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [filter_query, Setfilter_query] = React.useState({
    search: null,
  });
  const { isLoading, data , callFetch } = useFetch({
    initialUrl: `/offers`,
    skipOnStart: false,
    config: {
      method: "get",
    },
  });

  // Function to toggle the active status of an offer



  
  const toggleActiveOffer = async (offerId) => {
    try {
      const authToken = session;
      // '417|uDydQud7QjR1738UTh2PfAzBQqRP44LOPnaaGQMh3c69c878'; // Retrieve auth token from local storage using the correct constant
      console.log('au thToken: ', authToken);
      await clientWithHeaders({
        method: 'POST',
        url: `/offers/${offerId}/toggle-active`,
        headers: {
          Authorization: `Bearer ${authToken}`, // Include auth token in headers
        },
        // Optionally, you can include any other headers or body parameters here
      });

      // Refetch Offer List
      callFetch()


  
      // Optionally, you can also handle success scenarios if needed
    } catch (error) {
      console.error('Error toggling active status:', error);
      // Handle errors if needed
    }
  };
  
  
  
  
  React.useEffect(() => {
    Object.keys(filter_query).forEach(
      (key) =>
        (filter_query[key] === undefined ||
          filter_query[key] === null ||
          filter_query[key] === "") &&
        delete filter_query[key]
    );
    let str = Object.keys(filter_query)
      .map(function (key) {
        return key + "=" + filter_query[key];
      })
      .join("&");
    console.log(str, " str params ", filter_query);
    callFetch({
      url: `/offers?page=${1}&${str}`,
      method: "get",
    });
  }, [filter_query]);

  const paginationAction = React.useCallback(
    (page, b) => {
      if (!isLoading) {
        setCurrentPage(page);
        Object.keys(filter_query).forEach(
          (key) =>
            (filter_query[key] === undefined ||
              filter_query[key] === null ||
              filter_query[key] === "") &&
            delete filter_query[key]
        );

        const str = Object.keys(filter_query)
          .map(function (key) {
            return key + "=" + filter_query[key];
          })
          .join("&");
        callFetch({
          url: `/offers?page=${page}&${str}`,
          method: "get",
        });
      }
    },
    [callFetch, filter_query, isLoading]
  );

  const redirectIT = React.useCallback(
    (e) => {
      if (e) {
        navigate(e);
      }
    },
    [navigate]
  );
  React.useEffect(() => {
    if (isLoading) {
      SetState(false);
    }
  }, [isLoading]);

  const searchingFilter = React.useCallback(() => {
    Setfilter_query({
      ...filter_query,
      search: state,
    });
  }, [filter_query , state]);

  const Button = React.memo(({ IconClassName, color, icon, children }) => (
    <button className="bg-white w-full text-center hover:bg-gray-100 flex text-gray-800 font-semibold py-1 px-4 border border-gray-400 rounded shadow">
      <span>
        <IconProvider className={IconClassName ?? ""} color={color}>
          {icon}
        </IconProvider>
      </span>
      <span>{children}</span>
    </button>
  ));

  return (
    <React.Fragment>
      <div className=" mt-3">
        <BoxCantainer>
          <React.Fragment>
            <div className="w-full my-2 ">
              <div className="flex justify-between ">
                <div className=""></div>
                <div className="grid lg:grid-cols-6 md:grid-cols-9 grid-cols-2">
                  <div className="lg:col-span-5 md:col-span-2 col-span-1 px-1 lg:py-0 md:py-0 py-1 ">
                    <Input
                      id="input_searchbar"
                      onChange={(e) => SetState(e?.target.value)}
                      allowClear
                      required
                      style={{ width: "100% ", boxShadow: "none" }}
                      placeholder="Search..."
                      prefix={<BiSearch />}
                      addonAfter={
                        <div
                          className="cursor-pointer "
                          onClick={() => searchingFilter()}
                        >
                          Search
                        </div>
                      }
                    />
                  </div>
                  <div
                    className="lg:col-span-1 md:col-span-2 col-span-1"
                    onClick={() => redirectIT("/create-offer")}
                  >
                    <Button>ADD +</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:overflow-x-hidden md:overflow-x-hidden overflow-x-scroll ">
              {isLoading ? (
                <React.Fragment>
                  <Skeleton active />
                  <br />
                  <Skeleton active />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {data?.offers?.data?.length === 0 ? (
                    <div className="grid w-full">
                      <div className="m-auto">
                        <Empty />
                      </div>
                    </div>
                  ) : (
                    <table className="min-w-full leading-normal">
                      <thead>
                        <tr className="border-b border-t border-[#ccccd0]">
                        <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            {/* {enLangauge.USERS_TABLE_HEADER_LAST_PURCHASE} */}
                            ID
                          </th>
                          <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            {/* {enLangauge.USERS_TABLE_HEADER_LAST_PURCHASE} */}
                            CREATED BY
                          </th>
                          <th className="px-5 flex py-3   bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            <span className="mt-1">
                              {/* {enLangauge.USERS_TABLE_HEADER_CUSTOMER_NAME}
                               */}
                              TITLE & BODY
                            </span>
                          </th>
                          <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            {/* {enLangauge.USERS_TABLE_HEADER_LAST_PURCHASE} */}
                            PRICE
                          </th>
                          <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            {/* {enLangauge.USERS_TABLE_HEADER_EMAIL} */}
                            CATEGORY
                          </th>
                          <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            {/* {enLangauge.USERS_TABLE_HEADER_COUNTRY}
                             */}
                            AVERAGE RATING
                          </th>
                          <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            {/* {enLangauge.USERS_TABLE_HEADER_COUNTRY}
                             */}
                            CREATED AT
                          </th>
                          <th className="px-5 py-3  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            {enLangauge.USERS_TABLE_HEADER_STATUS}
                          </th>
                        </tr>
                      </thead>

                      <tbody>
  {data?.offers?.data?.map((data, index) => (
    <tr className="" key={index.toString()}>
      <td className="px-5 py-3 bg-white text-sm">
        <div className="flex">
          <div className="ml-3">
            <span
              className="text-gray-900 whitespace-no-wrap mt-2 cursor-pointer"
              onClick={() => redirectIT(`/update-offer/${data?.id}`)}
            >
              <div className="font-semibold">{data?.id}</div>
            </span>
          </div>
        </div>
      </td>
      <td className="px-5 py-3 bg-white text-sm">
        <div className="flex">
          <div className="ml-3">
            <span
              className="text-gray-900 whitespace-no-wrap mt-2 cursor-pointer"
              onClick={() => redirectIT(`/user/${data?.created_by?.id}`)}
            >
              <CustomeText>{data?.created_by?.name}</CustomeText>
              <div className="font-semibold">{data?.created_by?.role}</div>
            </span>
          </div>
        </div>
      </td>
      <td className="px-5 py-3 bg-white text-sm">
        <div className="flex">
          <div className="ml-3">
            <span
              className="text-gray-900 whitespace-no-wrap mt-2 cursor-pointer"
              onClick={() => redirectIT(`/user/${data?.id}`)}
            >
              <CustomeText>{data?.title}</CustomeText>
              <div className="font-semibold">{data?.body}</div>
            </span>
          </div>
        </div>
      </td>
      <td className="px-5 py-3 bg-white text-sm">
        <div className="flex">
          <div className="ml-3">
            <span className="text-gray-900 whitespace-no-wrap mt-2 cursor-pointer">
              <div className="font-semibold">{data?.price}</div>
            </span>
          </div>
        </div>
      </td>
      <td className="px-5 py-3 bg-white text-sm">
        <span className="text-gray-900 whitespace-no-wrap">
          <CustomeText>{data?.category?.name}</CustomeText>
        </span>
      </td>
      <td className="px-5 py-3 bg-white text-sm">
        <span className="text-gray-900 whitespace-no-wrap">
          <CustomeText>{data?.ratings?.average_rating}</CustomeText>
        </span>
      </td>
      <td className="px-5 py-3 bg-white text-sm">
        <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
          <span aria-hidden className="absolute inset-0 bg-green-200 opacity-50 rounded-full" />
          <span className="relative">{data?.created_at}</span>
        </span>
      </td>
      {/* Toggle active status cell */}
      <td className="px-5 py-3 bg-white text-sm">
        <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
          <span aria-hidden className="absolute inset-0 bg-green-200 opacity-50 rounded-full" />
          <span className="relative">
            <Status
              theme={{}}
              onClick={() => toggleActiveOffer(data.id)}
            >
              {data.status === "active" ? "Active" : "InActive"}
            </Status>
          </span>
        </span>
      </td>
    </tr>
  ))}
</tbody>

                    </table>
                  )}
                </React.Fragment>
              )}
            </div>
            <div className="pt-2 lg:px-3 md:px-2 px-0">
              <PaginationContainer
                labelText={` Page Number ${1} of ${Math.ceil(
                  data?.offers?.total / 10
                )}`}
              >
                <Pagination
                  showSizeChanger={false}
                  defaultCurrent={1}
                  current={currentPage}
                  defaultPageSize={10}
                  total={data?.offers?.total}
                  onChange={paginationAction}
                />
              </PaginationContainer>
            </div>
          </React.Fragment>
        </BoxCantainer>
      </div>
    </React.Fragment>
  );
};

export default OfferListing;

const BoxCantainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
  && @media only screen and (max-width:760px) {
    overflow-x: scroll;
  }
`;

const CustomeText = styled.div`
  // font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #6e7079;
`;
const Status = styled.button`
  background: ${(props) => props?.theme?.bg ?? "rgba(22, 192, 152, 0.38)"};
  width: 80px;
  color: ${(props) => props?.theme?.color ?? "#00B087"};
  height: 27px;
  border: ${(props) => props?.theme?.color ?? "1px solid #00B087"};
  border-radius: 4px;
`;