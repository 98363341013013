import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { ServiceCardData } from "utils/ObjectUtils";
import { IconProvider } from "utils/common.utils";
import { HiOutlineDotsHorizontal, HiOutlineDotsVertical } from "react-icons/hi";
import { BsPlusCircleFill } from "react-icons/bs";
import { Tooltip, Skeleton } from "antd";
import { ColumnGraph, Tab, BarGraph } from "Component";
import SiteSpeed from "./SiteSpeed";
import { useFetch } from "hooks";

const DashboardChild = () => {
  const [totalAgents, setTotalAgents] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalOffers, setTotalOffers] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Define isLoading state
  const [activeStat, setActiveStat] = useState("user");

  const { data: agentsData, loading: isAgentsLoading } = useFetch({
    initialUrl: "https://apibuditravel.com/api/admin/agents",
    skipOnStart: false,
  });

  const { data: userStat, loading: isUserStatLoading } = useFetch({
    initialUrl: "https://apibuditravel.com/api/admin/user_chart",
    skipOnStart: false,
  });

  const { data: agentStat, loading: isAgentStatLoading } = useFetch({
    initialUrl: "https://apibuditravel.com/api/admin/agent_chart",
    skipOnStart: false,
  });

  const { data: offerStat, loading: isOfferStatLoading } = useFetch({
    initialUrl: "https://apibuditravel.com/api/admin/offer_chart",
    skipOnStart: false,
  });

  const { data: usersData, loading: isUsersLoading } = useFetch({
    initialUrl: "https://apibuditravel.com/api/admin/users",
    skipOnStart: false,
  });

  const { data: offersData, loading: isOffersLoading } = useFetch({
    initialUrl: "https://apibuditravel.com/api/admin/offers",
    skipOnStart: false,
  });

  useEffect(() => {
    if (agentsData && agentsData.agents && agentsData.agents.total) {
      setTotalAgents(agentsData.agents.total);
    }
  }, [agentsData]);

  useEffect(() => {
    if (usersData && usersData.users && usersData.users.total) {
      setTotalUsers(usersData.users.total);
    }
  }, [usersData]);

  useEffect(() => {
    if (offersData && offersData.offers && offersData.offers.total) {
      setTotalOffers(offersData.offers.total);
    }
  }, [offersData]);

  useEffect(() => {
    // Set isLoading to false once data fetching is complete
    if (!isAgentsLoading && !isUsersLoading && !isOffersLoading) {
      setIsLoading(false);
    }
  }, [isAgentsLoading, isUsersLoading, isOffersLoading]);

  const ServiceCard = React.memo((props) => {
    return (
      <React.Fragment>
        <CardOFService
          style={{
            backgroundColor: props?.bgColor,
            boxShadow: props?.dropShadow,
          }}
        >
          <div className="grid  grid-cols-6">
            <div className="col-span-5">
              <span className="text-white font-semibold">{props?.title}</span>
            </div>
            <div className="col-span-1">

            </div>
          </div>
          <div>
            <section className="">
              {props.title === "Agents" && (
                <h1 className="py-5 text-white text-center  text-3xl font-semibold">
                  {totalAgents}
                </h1>
              )}
              {props.title === "Users" && (
                <h1 className="py-5 text-white text-center  text-3xl font-semibold">
                  {totalUsers}
                </h1>
              )}
              {props.title === "Offers" && (
                <h1 className="py-5 text-white text-center  text-3xl font-semibold">
                  {totalOffers}
                </h1>
              )}
              {props.title !== "Agents" &&
                props.title !== "Users" &&
                props.title !== "Offers" && (
                  <h1 className="py-5 text-white text-center  text-3xl font-semibold">
                    -
                  </h1>
                )}
            </section>
            <div className="grid  grid-cols-5">
              
              <div className="col-span-4">
  <div className="float-right">
    <Link to={props.link}>
      <CustomeBtn style={{ backgroundColor: props?.btnBgColor }}>
        {props?.btnText}
      </CustomeBtn>
    </Link>
  </div>
</div>
            </div>
          </div>
        </CardOFService>
      </React.Fragment>
    );
  }, []);

  // tabsOne
  const TabsOneData = React.useMemo(() => {
    let tempData = [];
    console.log('activeStat: ', activeStat);
    if(activeStat == "user"){
      tempData = userStat;
    } else if (activeStat == "offer") {
      tempData = offerStat;
    } else if (activeStat == "agent") {
      tempData = agentStat;
    }
    let finalData = [];
    for(let i = 0; i < tempData?.months.length; i++){
      finalData.push({
        month: tempData?.months[i],
        count: tempData?.count[i]
      })
    }
    console.log('tempData: ', finalData);
    return [
      {
        label: "6 Month",
        key: "month",
        children: <ColumnGraph data={finalData.slice(5, finalData.length - 1)} />,
      },
      {
        label: "Year",
        key: "year",
        children: <ColumnGraph  data={finalData} />,
      },
    ];
  }, [userStat, offerStat, agentStat, activeStat]);

  const TabOneLeftComponent = React.memo(() => {
    return (
      <React.Fragment>
        <CustomeLabel>Stats</CustomeLabel>
      </React.Fragment>
    );
  }, []);

  const TabOneRightComponent = React.memo(() => {
    return (
      <React.Fragment>
        <div className="">
          <Tooltip
            placement="leftTop"
            color="black"
            title={
              <React.Fragment>
                <button onClick={() => setActiveStat("user")}>User </button><br></br>
                <button onClick={() => setActiveStat("agent")}>Agents </button><br></br>
                <button onClick={() => setActiveStat("offer")}>Offers </button><br></br>
              </React.Fragment>
            }
            arrowPointAtCenter
          >
            <span>
              <IconProvider
                className={`text-[4D5E80] text-lg float-right cursor-pointer `}
                color={`#4D5E80`}
              >
                <HiOutlineDotsVertical />
              </IconProvider>
            </span>
          </Tooltip>
        </div>
      </React.Fragment>
    );
  }, []);

  const TabOneProps = React.useMemo(() => {
    return {
      data: TabsOneData,
      leftComponet: <TabOneLeftComponent />,
      rightComponent: <TabOneRightComponent />,
    };
  }, [TabsOneData]);

  // tabTwo
  const TabsTwoData = React.useMemo(() => {
    return [
      {
        label: "Weekly",
        key: "weekly",
        children: <BarGraph />,
      },
      {
        label: "Month",
        key: "month",
        children: <BarGraph />,
      },
      {
        label: "Year",
        key: "year",
        children: <BarGraph />,
      },
    ];
  }, []);

  const TabTwoLeftComponent = React.memo(() => {
    return (
      <React.Fragment>
        <CustomeLabel>Activities</CustomeLabel>
      </React.Fragment>
    );
  }, []);

  const TabTwoRightComponent = React.memo(() => {
    return (
      <React.Fragment>
        <div className="">
          <Tooltip
            placement="leftTop"
            color="black"
            title={
              <React.Fragment>
                <button>Click </button>
                <p className="cursor-pointer">lorem ipsum </p>
              </React.Fragment>
            }
            arrowPointAtCenter
          >
            <span>
              <IconProvider
                className={`text-[4D5E80] text-lg float-right cursor-pointer `}
                color={`#4D5E80`}
              >
                <HiOutlineDotsVertical />
              </IconProvider>
            </span>
          </Tooltip>
        </div>
      </React.Fragment>
    );
  }, []);

  const TabTwoProps = React.useMemo(() => {
    return {
      data: TabsTwoData,
      leftComponet: <TabTwoLeftComponent />,
      rightComponent: <TabTwoRightComponent />,
    };
  }, [TabsTwoData]);

  // Site speed

  const SiteSpeedComponent = React.useMemo(() => {
    return [
      {
        label: "Weekly",
        key: "weekly",
        children: <SiteSpeed />,
      },
      {
        label: "Month",
        key: "month",
        children: <SiteSpeed />,
      },
      {
        label: "Year",
        key: "year",
        children: <SiteSpeed />,
      },
    ];
  }, []);

  const SiteSpeedTabLeftComponent = React.memo(() => {
    return (
      <React.Fragment>
        <CustomeLabel>Speed Test</CustomeLabel>
      </React.Fragment>
    );
  }, []);

  const SiteSpeedTabRightComponent = React.memo(() => {
    return (
      <React.Fragment>
        <div className="">
          <Tooltip
            placement="leftTop"
            color="black"
            title={
              <React.Fragment>
                <button>Click </button>
                <p className="cursor-pointer">lorem ipsum </p>
              </React.Fragment>
            }
            arrowPointAtCenter
          >
            <span>
              <IconProvider
                className={`text-[4D5E80] text-lg float-right cursor-pointer `}
                color={`#4D5E80`}
              >
                <HiOutlineDotsVertical />
              </IconProvider>
            </span>
          </Tooltip>
        </div>
      </React.Fragment>
    );
  }, []);

  const SiteSpeedProps = React.useMemo(() => {
    return {
      data: SiteSpeedComponent,
      leftComponet: <SiteSpeedTabLeftComponent />,
      rightComponent: <SiteSpeedTabRightComponent />,
    };
  }, [SiteSpeedComponent]);

  return (
    <React.Fragment>
      <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-3">
        <div className="">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 gap-4">
            {ServiceCardData?.map((props, index, array) =>
              isLoading ? (
                <Skeleton className="m-3" active key={index} />
              ) : (
                <ServiceCard {...props} key={index} />
              )
            )}
          </div>
          <div className="mt-8">
            <BoxCantainer>
              {isLoading ? (
                <Skeleton className="mt-3" active />
              ) : (
                <Tab props={TabOneProps} />
              )}
            </BoxCantainer>
          </div>
        </div>
        <div className="lg:pl-3 md:pl-0 pl-0">
          <BoxCantainer>
            {isLoading ? (
              <Skeleton className="mt-3" active />
            ) : (
              <Tab props={SiteSpeedProps} />
            )}
          </BoxCantainer>
          <div className="mt-8">
            <BoxCantainer>
              {isLoading ? (
                <Skeleton className="mt-3" active />
              ) : (
                <Tab props={TabTwoProps} />
              )}
            </BoxCantainer>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardChild;

const CardOFService = styled.div`
  height: 160px;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
`;

const CustomeBtn = styled.button`
  height: 30px;
  border-radius: 100px;
  font-size: 10px;
  font-weight: 700;
  padding-left: 15px;
  padding-right: 15px;
  color: #ffffff;
  transition: all ease 0.3s;
  &:hover:hover {
    opacity: 0.8;
  }
`;

const AddBtn = styled.button`
  color: white;
  width: 30px;
  height: 30px;
  display: grid;
  border-radius: 50%;
  transition: all ease 0.3s;
  &:hover:hover {
    opacity: 0.8;
  }
`;

const BoxCantainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 10px;
  padding: 0px 15px 15px 15px;
`;

const CustomeLabel = styled.div`
  font-weight: 700;
  font-size: 13px;
  color: #4d5e80;
`;
