import React, { useState, useEffect } from 'react';
import { RingProgress } from '@ant-design/plots';
import styled from "styled-components";

const SiteSpeed = () => {
  const [pageLoadTime, setPageLoadTime] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [apiCalls, setApiCalls] = useState(null);
  const [pageSize, setPageSize] = useState(null);

  useEffect(() => {
    const measurePageLoadTime = () => {
      // Measure page load time using the Performance API
      const loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
      setPageLoadTime(loadTime);
      setIsLoading(false);
    };

    // Simulate fetching API call count and page size
    setTimeout(() => {
      setApiCalls(10); // Example value for API calls
      setPageSize('1.9mb'); // Example value for page size
    }, 1000);

    // Wait for the page to fully load before measuring page load time
    window.addEventListener('load', measurePageLoadTime);

    return () => {
      // Clean up event listener on component unmount
      window.removeEventListener('load', measurePageLoadTime);
    };
  }, []);

  return (
    <React.Fragment>
      <div className='grid lg:grid-cols-12 md:grid-cols-1 grid-cols-1 p-5'>
        <div className="col-span-4 grid">
          <div className="m-auto">
            <RingProgressCmp percent={(isLoading || !pageLoadTime) ? 0 : 1} />
          </div>
        </div>
        <div className="col-span-8">
          <div className="grid w-full lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4">
            <DetailContainer>
              <div>
                <strong>{isLoading ? 'Loading...' : `${pageLoadTime || 0} ms`}</strong>
                <div>Page Load Time</div>
              </div>
            </DetailContainer>
            <DetailContainer>
              <div>
                <strong>{apiCalls || '-'}</strong>
                <div>API Calls</div>
              </div>
            </DetailContainer>
            <DetailContainer>
              <div>
                <strong>{pageSize || '-'}</strong>
                <div>Page Size</div>
              </div>
            </DetailContainer>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const RingProgressCmp = ({ percent }) => {
  const config = {
    height: 100,
    width: 100,
    autoFit: false,
    color: ['#2B4C9B', '#E8EDF3'],
    innerRadius: 0.9,
    radius: 0.98,
    statistic: {
      title: {
        style: {
          color: '#2B4C9B',
          fontSize: '15px',
          lineHeight: '14px',
        },
        formatter: () => 'ms',
      },
    },
    percent: percent,
  };
  return <RingProgress type="circle" {...config} />;
};

export default SiteSpeed;

const DetailContainer = styled.div`
  background: rgba(51, 97, 255, 0.05);
  border-radius: 10px;
  padding: 16px 3px;
  text-align: center;
  color: #2B4C9B;
`;
