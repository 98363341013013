import React from "react";
import styled from "styled-components";
import { useFetch } from "hooks";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form";
import { Button } from "Component";
import { Input, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function OfferAction() {
  const navigate = useNavigate();
  const id = useParams();
  const redirect__it = React.useCallback(
    (option) => {
      navigate(option);
    },
    [navigate]
  );
  const { isLoading: categoryLoading, data: categorydata } = useFetch({
    initialUrl: "/categories/",
    skipOnStart: false,
    config: {
      method: "get",
    },
  });
  const catgory_data = React.useMemo(() => {
 return  !categoryLoading? categorydata?.categories?.map((i )=>({value:i.id , label:i.name})):[]
  }, [categoryLoading , categorydata]);
  const methods = useForm({
    // resolver:
    mode: "all",
    // defaultValues:{}
  });
  const onSuccess = React.useCallback(
    (response) => {
      if(response?.message !== "Successfully fetched offer"){
      toast.success(response?.message);
      redirect__it('/offers')
      }
    },
    [redirect__it]
  );

  const onFailure = React.useCallback((error) => {
    toast.success(error?.message);
  }, []);

  const { isLoading, data, callFetch } = useFetch({
    initialUrl: `/offers/${id?.id}`,
    skipOnStart: !Boolean(id?.id),
    config: {
      method: "get",
    },
    onSuccess,
    onFailure,
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, isValid },
  } = methods;

  const onSubmit = React.useCallback(
    (data) => {
      console.log(data)
      const formData = new FormData();
      formData.append("category_id", data.category);
      formData.append("body", data?.body);
      formData.append("title", data?.title);
      formData.append("price", data?.price);
      formData.append("active", data?.status==='Active'?1:0);
      if (id?.id) {
        formData.append("thumbnail", data?.thumbnail);
        if (data?.product_img.length > 0) {
          data?.product_img.map((file) => {
            formData.append("offer_images", file?.product_img);
            return file;
          });
        }
        callFetch({
          url: `/offers/${id?.id}`,
          method: "post",
          data: formData,
        });
      } else {
        formData.append("thumbnail", data?.thumbnail[0]);
        if (data?.product_img.length > 0) {
          data?.product_img.map((file) => {
            formData.append("offer_images", file?.product_img);
            return file;
          });
        }
        callFetch({
          url: `/offers/create`,
          method: "post",
          data: formData,
        });
      }
    },
    [callFetch, id]
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: "product_img",
  });

  React.useEffect(() => {
    if (!isLoading && id?.id) {
      const data__ = data?.offer;
      setValue("title", data__?.title, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("price", data__?.price, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("body", data__?.body, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue("status", data__?.status ==='active'?'Active':'InActive', {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue(
        "category",
        {
          label: data__?.category?.name,
          value: data__?.category?.id,
        },
        {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        }
      );
      setValue("thumbnail", data__?.thumbnail, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue(
        "product_img",
        data__?.offer_images?.map((i, index) => ({ product_img: i })),
        {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        }
      );
    }
  }, [isLoading, setValue, data, id]);

  return (
    <React.Fragment>
      <BoxCantainer>
        <div className=" pt-8 px-2 pb-3 ">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-control">
                <div className=" grid grid-cols-12 gap-4">
                  <div className="col-span-9">
                    <div className="grid grid-cols-12 gap-4 mb-3">
                      <div className="col-span-4 ">
                        <Controller
                          control={control}
                          name="title"
                          render={({
                            field,
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <React.Fragment>
                              <Input
                                className="rounded-md"
                                placeholder="Title"
                                {...field}
                              />
                              <p className=" px-2 mb-0 pt-0 text-xs text-error-color">
                                {error?.message}
                              </p>
                            </React.Fragment>
                          )}
                        />
                      </div>
                      <div className="col-span-4 ">
                        <Controller
                          control={control}
                          name="price"
                          render={({
                            field,
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <React.Fragment>
                              <Input
                                className="rounded-md"
                                type="number"
                                placeholder="Price"
                                {...field}
                              />
                              <p className=" px-2 mb-0 pt-0 text-xs text-error-color">
                                {error?.message}
                              </p>
                            </React.Fragment>
                          )}
                        />
                      </div>
                      <div className="col-span-4">
                        <Controller
                          control={control}
                          name="category"
                          render={({
                            field,
                            fieldState: { invalid, isTouched, isDirty, error },
                          }) => (
                            <Select
                            loading={categoryLoading}
                              style={{ width: "100%" }}
                              placeholder="Category"
                              {...field}
                              options={catgory_data}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <Controller
                      control={control}
                      name="body"
                      render={({
                        field,
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <React.Fragment>
                          <Input.TextArea
                            className="rounded-md"
                            placeholder="Offer Describitions"
                            {...field}
                          />
                          <p className=" px-2 mb-0 pt-0 text-xs text-error-color">
                            {error?.message}
                          </p>
                        </React.Fragment>
                      )}
                    />
                  </div>
                  <div className="col-span-3">
                    <div className="w-full">
                      <Controller
                        control={control}
                        name="thumbnail"
                        render={({
                          field,
                          fieldState: { invalid, isDirty, isTouched, error },
                        }) => {
                          let src = field.value ?? null;
                          if (
                            field.value &&
                            field.value.length > 0 &&
                            typeof field.value !== "string"
                          ) {
                            const objectUrl = URL.createObjectURL(
                              field.value[0]
                            );
                            src = objectUrl;
                          }

                          return (
                            <React.Fragment>
                              <ProfileImage>
                                {src ? (
                                  <Image src={src} alt="loading... " />
                                ) : (
                                  <UploadText>
                                    <div className="">
                                      <MdOutlineAddPhotoAlternate
                                        style={{ fontSize: "40px" }}
                                      />
                                    </div>
                                  </UploadText>
                                )}
                                <FileInput
                                  type="file"
                                  onChange={(e) =>
                                    field.onChange(e.target.files)
                                  }
                                />
                              </ProfileImage>
                              <p className=" px-2 text-[#f5594e] mb-0 pt-1 text-xs ">
                                {error?.message}
                              </p>
                            </React.Fragment>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className=""></div>
                </div>
              </div>
              <div className="form-control">
                <div className=" ">
                  <div className="">
                    <div className="grid grid-cols-12 gap-2">
                      <React.Fragment>
                        {fields?.map((i, index, array) => (
                          <div className="relative" key={index}>
                            <div className="absolute top-1 left-1 cursor-pointer">
                              <div
                                className="text-white  bg-[#0c0c0c4b] hover:bg-[#0c0c0c68] transition-all font-sm  pb-[3px] w-6 h-6 text-center rounded-full "
                                onClick={() => remove(index)}
                              >
                                {" "}
                                &times;
                              </div>
                            </div>
                            <ProfileImage>
                              <Image
                                style={{ width: "90px", height: "90px" }}
                                src={
                                  typeof i?.product_img === "object"
                                    ? URL.createObjectURL(i?.product_img)
                                    : i?.product_img
                                }
                                alt="loading..."
                              />
                            </ProfileImage>
                          </div>
                        ))}
                      </React.Fragment>
                      <div className="col-span-1">
                        <ProfileImage>
                          <UploadText>
                            <div className="py-5">
                              <MdOutlineAddPhotoAlternate
                                style={{ fontSize: "40px" }}
                              />
                            </div>
                          </UploadText>
                          <FileInput
                            type="file"
                            onChange={(e) => {
                              append({ product_img: e.target.files[0] });
                            }}
                          />
                        </ProfileImage>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" mt-6 flex justify-between">
                <div className="">
                  <div className="">
                    <Controller
                      control={control}
                      name="status"
                      render={({
                        field,
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <Select
                          style={{ width: "180px" }}
                          placeholder="Status"
                          {...field}
                          options={[
                            { value: "Active", label: "Active" },
                            { value: "InActive", label: "InActive" },
                          ]}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="">
                  <Button
                    type="submit"
                    className={`w-[130px] bg-primary-color rounded-full btn `}
                    isLoading={isLoading}
                    isDisabled={!isDirty || !isValid || isLoading}
                  >
                    {"Add"}
                  </Button>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </BoxCantainer>
    </React.Fragment>
  );
}

const BoxCantainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(38, 51, 77, 0.03);
  border-radius: 10px;
  padding: 0px 15px 15px 15px;
`;

const ProfileImage = styled.label`
  width: 100%;
  padding: 10px;
  background: #ffffff;
  border: 1px dashed #c0c0c0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
`;
const UploadText = styled.span`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 16px;
  color: #000000;
`;

const FileInput = styled.input`
  display: none;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
